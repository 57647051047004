import { FileData, Employee, RecipientChannel } from '..';

export enum StepTypes {
  InfoStep = 'info',
  TocStep = 'toc',
  TextStep = 'text',
  VideoStep = 'video',
  QuestionStep = 'question',
  InvitationStep = 'invitation',
  FileStep = 'file',
  MultipleChoiceStep = 'multipleChoice',
  ConsentStep = 'consent',
  ScaleStep = 'scale',
  FeedbackStep = 'feedback',
  TestStep = 'test',
  TestStart = 'test-start-text',
  TestInstruction = 'test-instructions',
  TestQuestion = 'test-question',
  TestSubmission = 'test-submission',
  TestResults = 'test-results',
  TestCompleted = 'test-completed'
}

export interface Step {
  stepId?: string;
  stepType: StepTypes;
  isNew: boolean;
  title: string;
  stepNumber?: number;
  isCollapsed?: boolean;
  response?: any;
  error?: boolean;
  requireResponse?: boolean;
}

export interface InfoStep extends Step {
  id: string;
  description: string;
  dateStart: string;
  dateEnd: string;
  channel: RecipientChannel;
  recipients: Employee[];
}

export interface TestStepInterface extends Step {
  stepType: StepTypes.TestStep;
  introduction: {
    title: string;
    description: string;
  };
  instructions: {
    title: string;
    description: string;
  };
  submission: {
    title: string;
    description: string;
  };
  completedMessage: {
    passed: string;
    failed: string;
  };
  passPercent: number;
  tryCount: number;
  questions: {
    title: string;
    description: string;
    options: MultipleChoiceOption[];
  }[];
}

export interface TestQuestionStepInterface extends Step {
  stepType: StepTypes.TestQuestion;
  title: string;
  description: string;
  options: MultipleChoiceOption[];
  response?: MultipleChoiceOption;
}

export interface TestStartInterface extends Step {
  stepType: StepTypes.TestStart;
  message: string;
  tryCount: number;
  maxTryCount: number;
}

export interface TestResultsInterface extends Step {
  stepType: StepTypes.TestResults;
  score: number;
  scores: { title: string; answeredCorrectly: boolean }[];
  passPercent: number;
  passed: boolean;
  passedMessage: string;
  failedMessage: string;
}

export interface FileStepInterface extends Step {
  stepType: StepTypes.FileStep;
  description: string;
  files: FileData[];
}

export interface TextStepInterface extends Step {
  stepType: StepTypes.TextStep;
  message: string;
}

export interface TestCompletedInterface extends Step {
  stepType: StepTypes.TestCompleted;
  message: string;
}

export interface TestInstructionInterface extends Step {
  stepType: StepTypes.TestInstruction;
  message: string;
}

export interface TestSubmissionInterface extends Step {
  stepType: StepTypes.TestSubmission;
  message: string;
}

export interface FeedbackStepInterface extends Step {
  stepType: StepTypes.FeedbackStep;
  description: string;
  response?: string;
}

export interface QuestionStepInterface extends Step {
  stepType: StepTypes.QuestionStep;
}

export interface ScaleStepInterface extends Step {
  stepType: StepTypes.ScaleStep;
  description: string;
  minLabel: string;
  maxLabel: string;
  response?: number;
}

export interface VideoStepInterface extends Step {
  stepType: StepTypes.VideoStep;
  videoUrl: string;
  description: string;
  metadata?: {
    length?: number | string;
    description?: string;
    title?: string;
  };
  response?: {
    ended?: boolean;
  };
}

export interface MultipleChoiceStepInterface extends Step {
  stepType: StepTypes.MultipleChoiceStep;
  description: string;
  options: MultipleChoiceOption[];
  response?: MultipleChoiceOption;
}

export interface ConsentStepInterface extends Step {
  stepType: StepTypes.ConsentStep;
  description: string;
  options: MultipleChoiceOption[];
  responses?: MultipleChoiceOption[];
}

export interface MultipleChoiceOption {
  option: string;
  description?: string;
  isCorrect: boolean;
  isSelected: boolean; // use clicked on this option
}

export interface InvitationStepInterface extends Step {
  stepType: StepTypes.InvitationStep;
  description: string;
  fromDate: string;
  toDate: string;
  location: Location;
  response?: {
    attending: boolean;
    message?: string;
  };
}

export interface TocChapter {
  stepId?: string;
  stepNumber: number;
  title: string;
}
export interface TocStepInterface extends Step {
  stepType: StepTypes.TocStep;
  description: string;
  chapters: TocChapter[];
}

export interface Location {
  name: string;
  latlng: { lat: number; lng: number };
}

export type QuestionType = 'multipleChoice' | 'scale' | 'feedback';

export type StepTypeUnion =
  | TextStepInterface
  | InfoStep
  | FileStepInterface
  | VideoStepInterface
  | InvitationStepInterface
  | ScaleStepInterface
  | FeedbackStepInterface
  | MultipleChoiceStepInterface
  | ConsentStepInterface
  | TestQuestionStepInterface
  | TestCompletedInterface;
