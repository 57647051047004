import { LayoutActionTypes, LayoutActions } from '../actions/layout.actions';
import * as AuthActions from '../../../auth/actions/auth.actions';
import { createReducer, on } from '@ngrx/store';

export interface State {
  archivedFilter: boolean;
  draftFilter: boolean;
  scheduledFilter: boolean;
  sentFilter: boolean;
}

const initialState: State = {
  archivedFilter: false,
  draftFilter: false,
  scheduledFilter: false,
  sentFilter: false
};

const layoutReducer = createReducer(
  initialState,
  on(AuthActions.Logout, () => initialState)
);

export function reducer(
  state: State = initialState,
  action: LayoutActions
): State {
  switch (action.type) {
    case LayoutActionTypes.ToggleArchivedFilter:
      return {
        ...state,
        archivedFilter: !state.archivedFilter,
        draftFilter: false,
        sentFilter: false,
        scheduledFilter: false
      };

    case LayoutActionTypes.ToggleDraftFilter:
      return {
        ...state,
        draftFilter: !state.draftFilter,
        archivedFilter: false,
        sentFilter: false,
        scheduledFilter: false
      };

    case LayoutActionTypes.ToggleScheduledFilter:
      return {
        ...state,
        scheduledFilter: !state.scheduledFilter,
        draftFilter: false,
        sentFilter: false,
        archivedFilter: false
      };

    case LayoutActionTypes.ToggleSentFilter:
      return {
        ...state,
        sentFilter: !state.sentFilter,
        draftFilter: false,
        archivedFilter: false,
        scheduledFilter: false
      };

    default: {
      return layoutReducer(state, action);
    }
  }
}

export const archivedFilter = (state: State) => state.archivedFilter;
export const draftFilter = (state: State) => state.draftFilter;
export const scheduledFilter = (state: State) => state.scheduledFilter;
export const sentFilter = (state: State) => state.sentFilter;
