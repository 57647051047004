import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthenticateRepsonse } from 'src/types';
import { map, tap } from 'rxjs/operators';

import {
  User,
  Authenticate,
  PasswordChange,
  Channel,
  Registration
} from 'src/types';

const URL = `${environment.api}/v1/auth`;
const REGISTRATION_URL = `${environment.api}/v1/auth/registration`;
const ORGANIZATION_URL = `${environment.api}/v1/organizations`;
const ZELO_URL = `${environment.api}/v1/zelos`;

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  login(creds: Authenticate) {
    return this.http.post<AuthenticateRepsonse>(`${URL}/login`, creds, {
      withCredentials: true
    });
  }

  loginSSO(creds: Authenticate) {
    return this.http.post<{ redirectUrl: string }>(`${URL}/login`, creds, {
      withCredentials: true
    });
  }

  loginSSOCode(code: string) {
    return this.http.post<AuthenticateRepsonse>(`${URL}/login/sso/callback`, {
      code
    });
  }

  forgot(email: string): Observable<any> {
    return this.http.post(`${URL}/forgot`, { email });
  }

  change(creds: PasswordChange): Observable<any> {
    return this.http.post(`${URL}/change`, creds);
  }

  logout(redirect = '/auth/login') {
    localStorage.clear();
    return this.router.navigateByUrl(redirect);
  }

  generateOverviewLink(email: string) {
    return this.http.post(`${URL}/overview/link`, { email });
  }

  signupForZelo(zeloId: string, signup: any) {
    return this.http.post(`${ZELO_URL}/${zeloId}/signup`, signup);
  }

  getMinimalZeloInfo(zeloId: string) {
    return this.http.get(`${ZELO_URL}/${zeloId}/signup`);
  }

  updateCurrentUser(user: User) {
    const u: User = { ...user, organization: null };
    return this.http.put<User>(`${URL}/users`, u);
  }

  saveRosterColumns(columns: string[]) {
    return this.http.post<string>(`${URL}/roster-columns`, columns);
  }

  createChannelCredentials(
    credentials: Omit<Channel, 'id' | 'createdAt' | 'updatedAt'>,
    code: string,
    organizationId: string
  ): Observable<Channel[]> {
    interface CredentialsWithCode
      extends Omit<Channel, 'id' | 'createdAt' | 'updatedAt'> {
      code: string;
    }

    const credentialsWithCode: CredentialsWithCode = {
      ...credentials,
      code
    };

    return this.http.post<Channel[]>(
      `${ORGANIZATION_URL}/${organizationId}/channels/${
        (credentialsWithCode as CredentialsWithCode).channelName
      }`,
      credentialsWithCode
    );
  }

  register(registration: Registration) {
    return this.http.post<AuthenticateRepsonse>(
      `${REGISTRATION_URL}`,
      registration
    );
  }

  refreshToken(): Observable<any> {
    return this.http.post<{}>(
      `${URL}/refresh-token`,
      {},
      {
        withCredentials: true,
        observe: 'response'
      }
    );
  }

  accessToken(): Observable<any> {
    return this.http
      .post<{ token: string }>(
        `${URL}/access-token`,
        {},
        {
          withCredentials: true,
          observe: 'response'
        }
      )
      .pipe(
        tap((response) => {
          localStorage.setItem('id_token', response.body.token);
        }),
        map((res) => {
          return { token: res.body.token };
        })
      );
  }
}
