import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import * as fromEnduser from './enduser.reducer';
import * as fromRoot from '../../core/reducers';
export interface EnduserState {
  responseData: fromEnduser.State;
}

export interface State extends fromRoot.State {
  enduser: EnduserState;
}

export const reducers: ActionReducerMap<EnduserState> = {
  responseData: fromEnduser.reducer
};

export const selectEnduser = createFeatureSelector<EnduserState>('enduser');

export const selectEnduserResponse = createSelector(
  selectEnduser,
  (state: EnduserState) => state.responseData
);

export const selectUserStatistics = createSelector(
  selectEnduserResponse,
  fromEnduser.getUserStatistics
);

export const getEnduserResponse = createSelector(
  selectEnduserResponse,
  (state: fromEnduser.State) => state.response
);

export const getEnduserSettings = createSelector(
  selectEnduserResponse,
  (state: fromEnduser.State) => state.endUserSettings
);

export const getAvailableChannels = createSelector(
  selectEnduserResponse,
  (state: fromEnduser.State) => state.availableChannels
);

export const getEnduserLoading = createSelector(
  selectEnduserResponse,
  (state: fromEnduser.State) => state.loading
);
