import {
  AttributeTerm,
  Zelo,
  ZeloMode,
  ZeloChannelMessage,
  ZeloBeeSaveRows,
  Category,
  ZeloSummary,
  Step,
  ZeloScheduleType,
  UserOrganization,
  OrganizationAttribute,
  Muster,
  MusterStation,
  AttrAudience,
  AttrAudienceKey,
  AttrZelo,
  Language,
  ZeloCourseCertificate
} from 'src/types';

import { createAction, props } from '@ngrx/store';
import { of } from 'rxjs';
import { ofType } from '@ngrx/effects';

export const enum Name {
  Noop = '[NOOP] No operation',
  Huzza = '[HTTP] Success',
  Brief = '[HTTP] Success with more information',
  Oops = '[HTTP] Update Error',
  NoSoup = '[HTTP] Get Error',
  PrefsSaved = '[Prefs] Saved',
  Muster = '[HTTP] Muster Audience To MusterStation',
  MusterEveryoneElse = '[HTTP] Muster Everyone Else Not In Audience To MusterStation',
  MusterChannelStats = '[Zelo] Muster Channel Stats',
  MusterSuccess = '[HTTP] Muster Audience To MusterStation Success',
  FetchAudienceTemplates = '[HTTP] Fetch AttrAudience',
  FetchAudienceTemplatesSuccess = '[HTTP] Fetch AttrAudience Success',
  SaveAttrZelo = '[HTTP] Save AttrZelo',
  SaveAttrZeloSuccess = '[HTTP] Save AttrZelo Success',
  SaveAudienceTemplate = '[HTTP] Save AttrAudience',
  SaveAudienceTemplateSuccess = '[HTTP] Save AttrAudience Success',
  DeleteAudienceTemplateSuccess = '[HTTP] Delete AttrAudience Success',
  FetchZeloSummary = '[zelos.next.effects] Fetch ZeloSummary array for Organization',
  ReduceZeloSummary = '[zelos.next.reducer] store ZeloSummary array for Organization',
  SelectZeloById = '[Zelo] Select By ID',
  SelectZeloByIdSuccess = '[Zelo] Select By ID Success',
  SelectZeloByIdFailure = '[Zelo] Select By ID Failure',
  StoreOnSelectedZelo = '[Zelo] Store Known Property On Selected Zelo State',
  AddAttributeTermToStore = '[Zelo] Add attribute term to attribute in store',
  StoreAudience = '[Zelo] Upsert Zelo Audience',
  LoadOrganizationAttributes = ' [Zelo] Loaf Organization Attributes',
  LoadOrganizationAttributesSuccess = '[Zelo] Load Organization Attributes Success',
  LoadOrganizationAttributesFailure = '[Zelo] Load Organization Attributes Failure',
  FetchOrg = '[Zelo] Fetch Org Details',
  FetchOrgSuccess = '[Zelo] Fetch Org Deatils Success',
  FetchOrgFailure = '[Zelo] Fetch Org Details Failure',
  UpsertZeloChannelMessages = '[Zelo] Upsert Zelo Channel Messages',
  UpsertZeloChannelMessagesSuccess = '[Zelo] Upsert Zelo Channel Messages Success',
  UpsertZeloChannelMessagesFailure = '[Zelo] Upsert Zelo Channel Messages Failure',
  SetZeloParent = '[Zelo] Set Zelo Parent',
  SetZeloArchived = '[Zelo] Set Zelo Archived',
  SetZeloRestored = '[Zelo] Set Zelo Restored',
  SetZeloTitle = '[Zelo] Set Zelo Title',
  SetZeloChannel = '[Zelo] Set Zelo Channel',
  SetZeloSchedule = '[Zelo] Set Zelo Schedule',
  SetZeloCategories = '[Zelo] Set Zelo Categories',
  SetZeloMode = '[Zelo] Set Zelo Mode',
  SetZeloModeSuccess = '[Zelo] Set Zelo Mode Success',
  SetZeloSenderId = '[Zelo] Set Zelo Sender ID',
  SetZeloSteps = '[Zelo] Set Zelo Steps',
  SetZeloStepsSuccess = '[Zelo] Set Zelo Steps Success',
  SetZeloSignup = '[Zelo] Set Zelo signup',
  SendZelo = '[Zelo] Send',
  SendZeloSuccess = '[Zelo] Send Success',
  SendZeloFailure = '[Zelo] Send Failure',
  SetBulkZeloParent = '[Zelo] Bulk Set Zelo Parent',
  SetBulkZeloArchive = '[Zelo] Bulk Set Zelo Archive',
  SetBulkZeloArchiveSuccess = '[Zelo] Bulk Set Zelo Archive Success',
  SetBulkZeloRestore = '[Zelo] Bulk Set Zelo Restored',
  SetBulkZeloCategories = '[Zelo] Bulk Set Zelo Categories',
  SetBulkZeloDelete = '[Zelo] Bulk Delete Zelo Categories',
  SetBulkZeloDeleteSuccess = '[Zelo] Bulk Set Zelo Delete Success',
  UpsertBeeSaveRows = '[Zelo] Upsert Bee Save Rows',
  UpsertBeeSaveRowsSuccess = '[Zelo] Upsert Bee Save Rows Success',
  UpsertBeeSaveRowsFailure = '[Zelo] Upsert Bee Save Rows Failure',
  DeleteRecipients = '[Zelo] Bulk Delete Recipients From Zelo',
  DeleteRecipientsSuccess = '[Zelo] Bulk Delete Recipients From Zelo Success',
  GetChannelStats = '[Zelo] Get Channel Stats',
  GetChannelStatsSuccess = '[Zelo] Get Channel Stats Success',
  GetChannelStatsFailure = '[Zelo] Get Channel Stats Failure',
  ClearState = '[Zelo] Clear State',
  setCourseCertificate = '[Zelo] Set Course Certificate',
  setCourseCertificateSuccess = '[Zelo] Set Course Certificate Success',
  setCourseCertificateFailure = '[Zelo] Set Course Certificate Failure',
  fetchCourseCertificate = '[Zelo] Fetch Course Certificate',
  fetchCourseCertificateSuccess = '[Zelo] Fetch Course Certificate Success',
  fetchCourseCertificateFailure = '[Zelo] Fetch Course Certificate Failure',
  RefreshZeloHTML = '[Zelo] Refresh Zelo HTML',
  RefreshZeloHTMLSuccess = '[Zelo] Refresh Zelo HTML Success'
}

const noopAction = createAction(Name.Noop, props<any>());
export const noop = () => noopAction({});

/**
 * Higher order huzza function.
 *
 * example:
 * this.actions$.pipe(
 *  ofType(someActions.someType),
 *  concatMap(({ stuff }) =>
 *    someService.someAPI(stuff).pipe(map(huzza('foo', { id: stuff.id })))
 * );
 *
 * result:
 * huzza$ effect runs, posting a success Toastr message with key "toasts.foo".  If
 * that i18n message was: "Posted to API with id: {{ id }}", then the resulting toast
 * message would show the id of the data given in the example's action.payload.
 *
 * Note that huzza$ has {dispatch: false}, so it must be the last action in a chain.
 *
 * @param i18n the string key into the translations file
 * @param substitutions an optional object containing substitutions present in i18n entry
 * @returns a function that returns an action of type Name.Huzza
 */

export const huzzaAction = createAction(
  Name.Huzza,
  props<{ i18n: string; substitutions?: any }>()
);

export const huzza = (i18n: string = Name.Huzza, substitutions?: any) => () =>
  huzzaAction({ i18n, substitutions });

export const ofTypeHuzza = () => ofType(huzzaAction);

/**
 * Higher order brief function.
 *
 * example:
 * this.actions$.pipe(
 *  ofType(someActions.someType),
 *  concatMap(({ stuff }) =>
 *    someService.someAPI(stuff).pipe(map(brief('foo', { id: stuff.id })))
 * );
 *
 * result:
 * brief$ effect runs, posting a success Toastr message with key "toasts.foo".  If
 * that i18n message was: "Posted to API with id: {{ id }}", then the resulting toast
 * message would show the id of the data given in the example's action.payload.
 *
 * Note that brief$ has {dispatch: false}, so it must be the last action in a chain.
 *
 * @param i18n the string key into the translations file
 * @param substitutions an optional object containing substitutions present in i18n entry
 * @returns a function that returns an action of type Name.Huzza
 */

const briefAction = createAction(
  Name.Brief,
  props<{ i18n: string; substitutions?: any }>()
);

export const brief = (i18n: string = Name.Brief, substitutions?: any) => () =>
  briefAction({ i18n, substitutions });

export const ofTypeBrief = () => ofType(briefAction);

/**
 * Higher order oops function.
 *
 * example:
 * this.actions$.pipe(
 *  ofType(someActions.someType),
 *  exhaustMap(({ stuff }) =>
 *    someService.someAPI(stuff).pipe(map(oops('foo', { id: stuff.id })))
 *  )
 * );
 *
 * result:
 * oops$ effect runs, posting a failure Toastr message with key "toasts.foo".  If
 * that i18n message was: "Failed to access id: {{ id }}", then the resulting toast
 * message would show the id of the data given in the example's action.payload.
 *
 * Note that oops$ has {dispatch: false}, so it must be the last action in a chain.
 *
 * @param i18n the string key into the translations file
 * @param substitutions an optional object containing substitutions present in i18n entry
 * @returns a function that returns an action of type Name.Oops
 */
export const oopsAction = createAction(
  Name.Oops,
  props<{ error: any; i18n: string; substitutions?: any }>()
);

export const oops = (i18n: string = Name.Oops, substitutions?: any) => (
  error: any
) => of(oopsAction({ error, i18n, substitutions }));

export const ofTypeOops = () => ofType(oopsAction);

export const musterDrillAt = createAction(
  Name.Muster,
  props<{ station: MusterStation }>()
);

export const musterEveryoneElse = createAction(
  Name.MusterEveryoneElse,
  props<{ station: MusterStation }>()
);

export const musterChannelStats = createAction(
  Name.MusterChannelStats,
  props<{ station: MusterStation }>()
);

export const ofTypeMuster = () => ofType(musterDrillAt);
export const ofTypeMusterEveryoneElse = () => ofType(musterEveryoneElse);
export const ofTypeMusterChannelStats = () => ofType(musterChannelStats);

export const musterSuccess = createAction(
  Name.MusterSuccess,
  props<{ station: MusterStation; muster: Muster }>()
);

export const saveAttrZelo = createAction(
  Name.SaveAttrZelo,
  props<{ attrZelo: AttrZelo; silent: boolean }>()
);

export const ofTypeSaveAttrZelo = () => ofType(saveAttrZelo);

export const saveAttrZeloSuccess = createAction(
  Name.SaveAttrZeloSuccess,
  props<{ attrZelo: AttrZelo; silent: boolean }>()
);

export const fetchAudienceTemplates = createAction(
  Name.FetchAudienceTemplates,
  props<{ key: AttrAudienceKey }>()
);

export const ofTypeFetchAudienceTemplates = () =>
  ofType(fetchAudienceTemplates);

export const fetchAudienceTemplatesSuccess = createAction(
  Name.FetchAudienceTemplatesSuccess,
  props<{
    templates: AttrAudience[];
    key: AttrAudienceKey;
  }>()
);

export const saveAudienceTemplate = createAction(
  Name.SaveAudienceTemplate,
  props<{
    template: AttrAudience;
    deleteHeadRevision: boolean;
    deletePermanently: boolean;
  }>()
);

export const ofTypeSaveAudienceTemplate = () => ofType(saveAudienceTemplate);

export const saveAudienceTemplateSuccess = createAction(
  Name.SaveAudienceTemplateSuccess,
  props<any>()
);

export const deleteAudienceTemplateSuccess = createAction(
  Name.DeleteAudienceTemplateSuccess,
  props<any>()
);

export const fetchZeloSummaryAction = createAction(
  Name.FetchZeloSummary,
  props<{ ids?: string[] }>()
);

export const fetchZeloManifest = () => fetchZeloSummaryAction({ ids: [] });

export const fetchZeloSummary = (ids: string[]) =>
  fetchZeloSummaryAction({ ids });

export const ofTypeFetchZeloSummary = () => ofType(fetchZeloSummaryAction);

/**
 * Action creator for type Name.ReduceZeloSummary
 */
export const reduceZeloSummaryFn = (zelos: ZeloSummary[]) =>
  reduceZeloSummary({ zelos });

export const reduceZeloSummary = createAction(
  Name.ReduceZeloSummary,
  props<{ zelos: ZeloSummary[] }>()
);

export const clearState = createAction(Name.ClearState);

/** Select Zelo By ID Actions */
export const selectZeloById = createAction(
  Name.SelectZeloById,
  props<{ zeloId: string }>()
);

export const selectZeloByIdSuccess = createAction(
  Name.SelectZeloByIdSuccess,
  props<{ zelo: Zelo }>()
);

export const selectZeloByIdFailure = createAction(Name.SelectZeloByIdFailure);

/**
 * Store known property on Selected Zelo in app state.
 * Subscribers to getSelectedZelo will be invoked.
 * See reducer for implementation.
 * Use a separate action to persist to API and database.
 * Persistence errors necessitate browser refresh to resync state.
 */
export const storeOnSelectedZelo = createAction(
  Name.StoreOnSelectedZelo,
  props<Record<string, any>>()
);

export const addAttributeTermToStore = createAction(
  Name.AddAttributeTermToStore,
  props<{ term: AttributeTerm | AttributeTerm[] }>()
);

/** Load organization Attributes */

export const loadOrganizationAttributes = createAction(
  Name.LoadOrganizationAttributes
);

export const loadOrganizationAttributesSuccess = createAction(
  Name.LoadOrganizationAttributesSuccess,
  props<{ attributes: OrganizationAttribute[] }>()
);

export const loadOrganizationAttributesFailure = createAction(
  Name.LoadOrganizationAttributesFailure,
  props<{ error: any }>()
);

export const fetchOrg = createAction(Name.FetchOrg);
export const fetchOrgSuccess = createAction(
  Name.FetchOrgSuccess,
  props<{ org: UserOrganization }>()
);
export const fetchOrgFailure = createAction(
  Name.FetchOrgFailure,
  props<{ error: any }>()
);

export const upsertZeloChannelMessages = createAction(
  Name.UpsertZeloChannelMessages,
  props<{ channelMessages: ZeloChannelMessage[] }>()
);

export const upsertZeloChannelMessagesSuccess = createAction(
  Name.UpsertZeloChannelMessagesSuccess,
  props<{ channelMessages: ZeloChannelMessage[] }>()
);

export const upsertZeloChannelMessagesFailure = createAction(
  Name.LoadOrganizationAttributesFailure,
  props<{ error: any }>()
);

export const setZeloParent = createAction(
  Name.SetZeloParent,
  props<{ id: string; parent: string }>()
);

export const setZeloArchived = createAction(
  Name.SetZeloArchived,
  props<{ id: string; archived?: boolean }>()
);

export const setZeloRestored = createAction(
  Name.SetZeloRestored,
  props<{ id: string; archived?: boolean }>()
);

export const setZeloTitle = createAction(
  Name.SetZeloTitle,
  props<{ id: string; title: string }>()
);

export const setZeloChannel = createAction(
  Name.SetZeloChannel,
  props<{
    station: MusterStation;
    channel: string;
  }>()
);

export const setZeloSchedule = createAction(
  Name.SetZeloSchedule,
  props<{
    id: string;
    scheduleType: ZeloScheduleType;
    startAt: string;
    startAtTimezone: string;
    finishBy: string;
    finishByTimezone: string;
  }>()
);

export const setZeloCategories = createAction(
  Name.SetZeloCategories,
  props<{ id: string; categories: Category[] }>()
);

export const setZeloMode = createAction(
  Name.SetZeloMode,
  props<{ id: string; mode: ZeloMode }>()
);

export const setZeloModeSuccess = createAction(
  Name.SetZeloModeSuccess,
  props<{ id: string; mode: ZeloMode; isValid: boolean }>()
);

export const setZeloSenderId = createAction(
  Name.SetZeloSenderId,
  props<{ id: string; senderId: string }>()
);

export const setZeloSteps = createAction(
  Name.SetZeloSteps,
  props<{ id: string; steps: Step[]; isValid?: boolean }>()
);

export const setZeloStepsSuccess = createAction(
  Name.SetZeloStepsSuccess,
  props<{ id: string; steps: Step[]; isValid?: boolean }>()
);

export const setZeloSignup = createAction(
  Name.SetZeloSignup,
  props<{
    id: string;
    notifyEmails: string[];
    signup: boolean;
    attributes: string[];
    signupLanguage: Language;
    anonymous?: boolean;
  }>()
);

export const sendZelo = createAction(Name.SendZelo, props<{ zelo: Zelo }>());

export const sendZeloSuccess = createAction(
  Name.SendZeloSuccess,
  props<{ zelo: Zelo }>()
);

export const sendZeloFailure = createAction(
  Name.SendZeloFailure,
  props<{ payload: unknown }>()
);

export const setBulkZeloParent = createAction(
  Name.SetBulkZeloParent,
  props<{ zeloIds: string[]; parent: string }>()
);

export const setBulkZeloArchive = createAction(
  Name.SetBulkZeloArchive,
  props<{ zeloIds: string[]; archived?: boolean }>()
);
export const setBulkZeloArchiveSuccess = createAction(
  Name.SetBulkZeloArchiveSuccess,
  props<{ data: any }>()
);

export const setBulkZeloRestore = createAction(
  Name.SetBulkZeloRestore,
  props<{ zeloIds: string[]; archived?: boolean }>()
);

export const setBulkZeloCategories = createAction(
  Name.SetBulkZeloCategories,
  props<{ zeloIds: string[]; categories: Category[] }>()
);

export const setBulkZeloDelete = createAction(
  Name.SetBulkZeloDelete,
  props<{ zeloIds: string[] }>()
);

export const setBulkZeloDeleteSuccess = createAction(
  Name.SetBulkZeloDeleteSuccess,
  props<{ data: any }>()
);

export const upsertBeeSaveRows = createAction(
  Name.UpsertBeeSaveRows,
  props<{ rows: ZeloBeeSaveRows }>()
);

export const upsertBeeSaveRowsSuccess = createAction(
  Name.UpsertBeeSaveRowsSuccess,
  props<{ rows: ZeloBeeSaveRows }>()
);

export const upsertBeeSaveRowsFailure = createAction(
  Name.UpsertBeeSaveRowsFailure,
  props<{ error: any }>()
);

export const deleteRecipients = createAction(
  Name.DeleteRecipients,
  props<{ zeloId: string; recipientIds: String[] }>()
);

export const deleteRecipientsSuccess = createAction(
  Name.DeleteRecipientsSuccess,
  props<{ data: any }>()
);

export const setCourseCertificate = createAction(
  Name.setCourseCertificate,
  props<{ zeloId: string; certificate: ZeloCourseCertificate }>()
);

export const setCourseCertificateSuccess = createAction(
  Name.setCourseCertificateSuccess,
  props<ZeloCourseCertificate>()
);

export const setCourseCertificateFailure = createAction(
  Name.setCourseCertificateFailure,
  props<{ error: any }>()
);

export const fetchCourseCertificate = createAction(
  Name.fetchCourseCertificate,
  props<{ zeloId: string }>()
);

export const fetchCourseCertificateSuccess = createAction(
  Name.fetchCourseCertificateSuccess,
  props<Partial<ZeloCourseCertificate>>()
);

export const fetchCourseCertificateFailure = createAction(
  Name.fetchCourseCertificateFailure,
  props<{ error: any }>()
);

export const refreshZeloHTML = createAction(
  Name.RefreshZeloHTML,
  props<{ zeloId: string }>()
);

export const refreshZeloHTMLSuccess = createAction(
  Name.RefreshZeloHTMLSuccess,
  props<ZeloChannelMessage>()
);
