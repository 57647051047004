<ng-container *ngIf="triggerObservable$ | async as endUserSettings">
  <div class="privacy-consent" *ngIf="isVisible">
    <p>
      <span>{{ 'settings.privacyPolicy.text' | translate }}</span>
      <span>
        {{ 'settings.privacyPolicy.visitText' | translate }}
        <a href="{{ privacyLink }}">
          {{ 'settings.privacyPolicy.title' | translate }}
          <zlo-icon name="external-link" width="20px"></zlo-icon
        ></a>
        {{ 'settings.privacyPolicy.learnMore' | translate }}
      </span>
      <span>
        <button
          type="button"
          name="button"
          class="button"
          (click)="acceptPolicy()"
        >
          Accept
        </button>
      </span>
    </p>
  </div>
</ng-container>
