import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  HostListener
} from '@angular/core';
import { sendEndUserSettings } from '../../enduserPortal/actions/enduser.actions';
import { Store } from '@ngrx/store';
import { EndUserSettings, LanguageObject, User, languageList } from 'src/types';

@Component({
  selector: 'zlo-country-selector',
  template: `
    <section
      *ngIf="endUserSettings"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (clickOutside)="clickOutside()"
      attachOutsideOnClick="true"
      delayClickOutsideInit="true"
      (keypress)="onKeypress($event)"
      class="dropdown parrent"
      id="open-global-dropdown"
      tabindex="0"
      aria-owns="avatar-menu"
      attr.aria-label="{{ 'menu.avatar' | translate }}"
    >
      <div
        class="flex cursor-pointer"
        aria-hidden="true"
        (click)="menuOpen = !menuOpen"
      >
        <button class="cursor-pointer">
          <span class="fi" [class]="currentLanguageObject?.flag"></span>
          {{ currentLanguageObject?.name }}
        </button>
      </div>
      <ul
        id="avator-menu"
        role="menu"
        *ngIf="menuOpen"
        class="card dropdown child shadow medium flex column"
      >
        <li
          *ngFor="let language of preferredLanguageList | keyvalue"
          (click)="selectedCountryChanged(language.value)"
          class="dropdown dropdown-link cursor-pointer flex gap-8"
          role="menuitem"
          tabindex="0"
        >
          <span class="fi" [class]="language.value.flag"></span>
          {{ language.value.name }}
        </li>
      </ul>
    </section>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectorComponent implements OnInit {
  @Input() endUserSettings: EndUserSettings;
  @Input() user: User;
  @Input() organizationLanguage: string;

  preferredLanguageList: { [key: string]: LanguageObject } = languageList;
  currentLanguageObject: LanguageObject = languageList.en;
  menuOpen = false;

  constructor(private store: Store) {}

  @HostListener('document:keydown', ['$event'])
  onKeypress(event: KeyboardEvent) {
    if (event.code === 'Escape') this.menuOpen = false;
  }

  ngOnInit() {
    if (this.endUserSettings?.preferredLanguage?.length) {
      this.currentLanguageObject = this.preferredLanguageList[
        this.endUserSettings.preferredLanguage
      ];
    } else {
      this.currentLanguageObject = this.preferredLanguageList[
        this.organizationLanguage
      ];
    }
  }

  ngOnChanges() {
    if (this.endUserSettings?.preferredLanguage?.length) {
      this.currentLanguageObject = this.preferredLanguageList[
        this.endUserSettings.preferredLanguage
      ];
    }
  }

  onFocus() {
    setTimeout(() => {
      const activeEl = document.activeElement.id;
      if (activeEl === 'open-global-dropdown') this.menuOpen = true;
    }, 10);
  }

  onBlur() {
    setTimeout(() => {
      const activeEl = document.activeElement.id;

      if (activeEl !== 'open-global-dropdown') {
        this.menuOpen = false;
      }
    }, 50);
  }

  clickOutside() {
    this.menuOpen = false;
  }

  selectedCountryChanged(lang: LanguageObject) {
    this.currentLanguageObject = lang;
    this.store.dispatch(
      sendEndUserSettings({
        endUserSettings: {
          ...this.endUserSettings,
          preferredLanguage: lang.id
        },
        userId: this.user.id
      })
    );
    this.menuOpen = false;
  }
}
