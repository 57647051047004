import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
  ToggleArchivedFilter = '[Layout] Toggle Archive Filter',
  ToggleDraftFilter = '[Layout] Toggle Draft Filter',
  ToggleScheduledFilter = '[Layout] Toggle Scheduled Filter',
  ToggleSentFilter = '[Layout] Toggle Sent Filter'
}

export class ToggleArchivedFilter implements Action {
  readonly type = LayoutActionTypes.ToggleArchivedFilter;
}

export class ToggleDraftFilter implements Action {
  readonly type = LayoutActionTypes.ToggleDraftFilter;
}

export class ToggleScheduledFilter implements Action {
  readonly type = LayoutActionTypes.ToggleScheduledFilter;
}

export class ToggleSentFilter implements Action {
  readonly type = LayoutActionTypes.ToggleSentFilter;
}

export type LayoutActions =
  | ToggleArchivedFilter
  | ToggleDraftFilter
  | ToggleScheduledFilter
  | ToggleSentFilter;
