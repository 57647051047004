import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Registration } from 'src/types';
import * as fromAuth from '../reducers';
import * as Auth from '../actions/auth.actions';
import * as fromCore from '../../core/reducers';
import { Title } from '@angular/platform-browser';
import { SegmentService } from 'src/app/shared/services/segment.service';

@Component({
  selector: 'zlo-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent implements OnInit {
  routerState$ = this.store.pipe(select(fromCore.getRouterState));
  providerSession = '';
  tier: 'STARTER' | 'STANDARD' | 'PREMIUM' | 'E2E' = 'E2E';

  constructor(
    private store: Store<fromAuth.State>,
    private route: ActivatedRoute,
    private titleService: Title,
    private segment: SegmentService
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Sign Up | Zelo');

    this.route.queryParams.subscribe((params) => {
      if (params.session_id) {
        this.providerSession = params.session_id;
        this.store.dispatch(Auth.Register({ sessionId: params.session_id }));
      }
      if (['starter', 'standard', 'premium', 'e2e'].includes(params.tier)) {
        this.tier = params.tier.toUpperCase();
      }
    });

    this.segment.track('Product Viewed', {
      tier: this.tier
    });
  }

  onSubmit($event: any, url: string) {
    if (url.includes('/free-trial')) {
      const registration = $event as Registration;
      return this.store.dispatch(Auth.Register(registration));
    }
  }
}
