import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormatPipe'
})
export class LocaleDatePipe implements PipeTransform {
  transform(value: string) {
    if (!value) return null;

    const navigatorLanguage = navigator.language || 'en-US';
    const event = new Date(value);
    const datePipe = new DatePipe(navigatorLanguage);
    // Set the date format to default - dd/MM/yyyy in case toLocaleDateString fails
    const datePipeValue =
      event.toLocaleDateString(navigatorLanguage, {}) ||
      datePipe.transform(event, 'dd/MM/yyyy');
    return datePipeValue;
  }
}
