import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Authenticate, PasswordChange } from 'src/types';
import * as fromAuth from '../reducers';
import * as Auth from '../actions/auth.actions';
import * as fromCore from '../../core/reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zlo-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  otpChannel: string = null;
  otpInvalid: boolean = false;
  otpSendLimit: boolean = false;
  otpVerifyLimit: boolean = false;
  otpTimeout: boolean = false;

  pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  routerState$ = this.store.pipe(select(fromCore.getRouterState));

  constructor(
    private store: Store<fromAuth.State>,
    public cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.store
        .pipe(select(fromAuth.getOtpChannel))
        .subscribe((channel: string) => {
          this.otpChannel = channel ? channel : this.otpChannel;
          this.otpInvalid = !channel;
          this.cdr.detectChanges();
        })
    );

    this.subscriptions.add(
      this.store
        .pipe(select(fromAuth.getOtpStatus))
        .subscribe((status: string) => {
          this.otpSendLimit = false;
          this.otpVerifyLimit = false;
          this.otpTimeout = false;

          if (status?.includes('Max send')) {
            this.otpSendLimit = true;
            this.otpInvalid = false;
          }

          if (status?.includes('Max check')) {
            this.otpVerifyLimit = true;
            this.otpInvalid = false;
          }

          if (status?.toLowerCase().includes('not found')) {
            this.otpTimeout = true;
            this.otpInvalid = false;
          }

          this.cdr.detectChanges();
        })
    );
  }

  onSubmit($event: Authenticate | PasswordChange | string, url: string) {
    if (url.endsWith('/auth/login')) {
      return this.store.dispatch(Auth.Login($event as Authenticate));
    }

    if (url.endsWith('/auth/sso')) {
      return this.store.dispatch(Auth.LoginSSO($event as Authenticate));
    }

    if (url.endsWith('/auth/forgot')) {
      return this.store.dispatch(Auth.Forgot({ payload: $event as string }));
    }
  }
}
