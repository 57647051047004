import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

/**
 * @title SVG icons
 */
@Component({
  selector: 'zlo-icon',
  template: `
    <mat-icon
      [svgIcon]="name"
      [ngStyle]="{
        width: width,
        height: height,
        transform: 'rotate(' + rotate + 'deg)',
        color: color
      }"
    ></mat-icon>
  `,
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() name = '';
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() rotate = 0;
  @Input() color: string = null;
  // assetsUrl = 'assets/images/iconset.svg#';
  iconsPath = '../../../../assets/images/';
  constructor(private iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.iconRegistry.addSvgIconSet(
      sanitizer.bypassSecurityTrustResourceUrl(this.iconsPath + 'iconset.svg')
    );
  }
}
