import { SignupComponent } from './containers/signup.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthEffects } from './effects/auth.effects';
import { reducers } from './reducers';
import { LoginPageComponent } from './containers/login-page.component';
import { LoginFormComponent } from './components/login-form.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './components/forgot-password.component';
import { SharedModule } from '../shared/shared.module';
import { OverviewLinkPromptComponent } from './containers/overview-link-prompt.component';

import { BackgroundComponent } from './components/background/background.component';
import { ExpiredSessionComponent } from './containers/expired-session.component';
import { TokenInterceptor } from './services/token.interceptor.service';
import { RegistrationPageComponent } from './containers/registration-page.component';
import { RegistrationFormComponent } from './components/registration-form.component';

import { AuthRoutingModule } from './auth-routing.module';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SsoComponent } from '../auth/components/sso.component';

export const COMPONENTS: any[] = [
  AuthComponent,
  ExpiredSessionComponent,
  ForgotPasswordComponent,
  LoginPageComponent,
  LoginFormComponent,
  RegistrationPageComponent,
  RegistrationFormComponent,
  OverviewLinkPromptComponent,
  SignupComponent,
  BackgroundComponent,
  SsoComponent
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([AuthEffects]),
    AuthRoutingModule,
    MatProgressSpinnerModule
  ],
  declarations: COMPONENTS,
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ]
})
export class AuthModule {}
