import { createAction, props } from '@ngrx/store';
import { Organization } from './organization.model';

enum LegacyOrganizationActionTypes {
  UpdateOrganization = '[Org] Update',
  UpdateOrganizationSuccess = '[Org] Update Success',
  UpdateOrganizationFailure = '[Org] Update Failure'
}

export const UpdateOrganization = createAction(
  LegacyOrganizationActionTypes.UpdateOrganization,
  props<{ organization: Partial<Organization>; isAdmin?: boolean }>()
);

export const UpdateOrganizationSuccess = createAction(
  LegacyOrganizationActionTypes.UpdateOrganizationSuccess,
  props<Partial<Organization>>()
);

export const UpdateOrganizationFailure = createAction(
  LegacyOrganizationActionTypes.UpdateOrganizationFailure,
  props<any>()
);
