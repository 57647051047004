<zlo-background>
  <div class="overview-form-component grid-x align-center text-center">
    <div class="padding-m margin-bl cell">
      <zlo-icon name="zelo-logo" width="100px"></zlo-icon>
    </div>

    <div class="cell grass"></div>

    <div class="cell">
      <form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="submit()">
        <div class="login-form-container grid-x">
          <div class="cell">
            <h4 class="login-title">
              {{ 'auth.overviewLink.header' | translate }}
            </h4>
          </div>
          <div *ngIf="!ngForm.submitted" class="cell text-left">
            <label>
              {{ 'auth.emailLabel' | translate }}
              <span
                class="error-text"
                *ngIf="
                  ngForm.submitted && form.get('email').hasError('required')
                "
              >
                {{ 'auth.errors.isRequired' | translate }}</span
              >
              <span
                class="error-text"
                *ngIf="ngForm.submitted && form.get('email').hasError('email')"
              >
                {{ 'auth.errors.isInvalid' | translate }}</span
              >
              <input
                type="text"
                [placeholder]="'auth.emailPlaceholder' | translate"
                formControlName="email"
              />
            </label>
          </div>
          <div *ngIf="!ngForm.submitted" class="cell">
            <button
              class="button small expanded login-button"
              type="submit"
              mat-button
            >
              {{ 'auth.overviewLink.button' | translate }}
            </button>
          </div>
          <div *ngIf="ngForm.submitted" class="cell">
            <p class="submitted-text">
              <zlo-icon name="check-circle" width="24px"></zlo-icon>
              {{ 'auth.overviewLink.submittedText' | translate }}
            </p>
          </div>
        </div>
      </form>
    </div>
    <div class="cell login-nav-link">
      <div class="grid-y">
        <div class="cell shrink">
          <a routerLink="/auth/login">
            {{ 'auth.login.header' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</zlo-background>
