<zlo-background [backgroundColors]="zeloSignup?.whitelabel"></zlo-background>

<div
  class="signup-container flex column align-center"
  *ngIf="shouldShowGrass()"
>
  <div class="logo">
    <zlo-icon name="zelo-logo" width="100px"></zlo-icon>
  </div>

  <div class="grass"></div>
</div>

<div
  class="signup-container flex column align-center"
  *ngIf="!shouldShowGrass()"
>
  <div class="logo">
    <img
      style="
        max-width: 150px;
        max-height: 150px;
        margin-left: auto;
        margin-right: auto;
      "
      src="{{
        zeloSignup?.orgLogo ||
          'https://zelo.app/assets/images/zelo-email-logo.png'
      }}"
    />
    <p *ngIf="zeloSignup.orgName" class="body-text center">
      "{{ zeloSignup.orgName }}"
    </p>
  </div>

  <div
    class="card max-width-480 flex column align-center"
    *ngIf="shouldShowTryAgain()"
  >
    <h1 class="heading center purple-500">
      {{ 'signup.messageSignup.doesNotExist' | translate }}
    </h1>
    <p>{{ 'signup.messageSignup.tryAgain' | translate }}</p>
  </div>

  <div
    class="card max-width-480 flex column align-center"
    *ngIf="shouldShowSuccess()"
  >
    <h1 class="purple-500">
      {{ 'signup.messageSignup.success' | translate }}
    </h1>
    <p class="body-text center">
      {{ 'signup.messageSignup.emailSent' | translate }}
    </p>
  </div>

  <div
    class="card full-width max-width-480 flex column gap-16 grow"
    *ngIf="shouldShowForm() || shouldShowAnonymous()"
  >
    <div class="flex column align-center">
      <p class="body-text">
        {{ 'signup.messageSignup.headerLabel' | translate }}
      </p>
      <h1 class="heading h1 purple-500 center">
        {{ zeloSignup?.zeloTitle }}
      </h1>
    </div>

    <div
      *ngIf="shouldShowAnonymous()"
      class="flex column full-width align-center"
    >
      <button
        *ngIf="isSigningUpAnonymous === ''; else signingup"
        class="button fixed-width-192px margin-tl"
        (click)="addAnonymousUser()"
        type="button"
      >
        {{ 'signup.messageSignup.getStartedButton' | translate }}
      </button>
    </div>

    <form
      *ngIf="shouldShowForm()"
      [formGroup]="userForm"
      (ngSubmit)="addUser()"
      #userFormRef="ngForm"
      class="flex column align-center"
    >
      <div class="full-width">
        <label
          [class.signup-form-error]="
            userFormRef.submitted &&
            userForm.get('firstname').hasError('required')
          "
        >
          {{ 'signup.userInput.firstNameLabel' | translate }}
          <span
            *ngIf="
              userFormRef.submitted &&
              userForm.get('firstname').hasError('required')
            "
          >
            {{ 'signup.userInput.isRequired' | translate }}
          </span>
          <input
            type="text"
            formControlName="firstname"
            cy-data="signup-firstname-input"
          />
        </label>
      </div>
      <div class="full-width">
        <label
          [class.signup-form-error]="
            userFormRef.submitted &&
            userForm.get('lastname').hasError('required')
          "
        >
          {{ 'signup.userInput.lastNameLabel' | translate }}
          <span
            *ngIf="
              userFormRef.submitted &&
              userForm.get('lastname').hasError('required')
            "
          >
            {{ 'signup.userInput.isRequired' | translate }}
          </span>
          <input
            type="text"
            formControlName="lastname"
            cy-data="signup-lastname-input"
          />
        </label>
      </div>
      <div class="full-width">
        <label
          [class.signup-form-error]="
            formSubmitted &&
            (userForm.get('email').hasError('required') ||
              userForm.get('email').hasError('pattern') ||
              userForm.get('email').hasError('emailExists'))
          "
        >
          {{ 'signup.userInput.emailLabel' | translate }}
          <span
            class="label-error"
            *ngIf="
              formSubmitted &&
              (userForm.get('email').hasError('required') ||
                userForm.get('email').hasError('pattern'))
            "
          >
            {{ 'signup.userInput.isRequiredEmail' | translate }}
          </span>
          <span
            class="label-error"
            *ngIf="
              formSubmitted && userForm.get('email').hasError('emailExists')
            "
          >
            {{ 'signup.userInput.emailIsTaken' | translate }}
          </span>
          <input
            type="text"
            formControlName="email"
            (keyup)="formatEmail()"
            cy-data="signup-email-input"
          />
        </label>
      </div>
      <div class="full-width">
        <label
          [class.signup-form-error]="
            formSubmitted &&
            (!userForm.get('phone').value ||
              userForm.get('phone').hasError('minlength') ||
              !isPhoneNumberValid)
          "
        >
          {{ 'signup.userInput.phoneLabel' | translate }}
          <span class="error-text" *ngIf="formSubmitted && !isPhoneNumberValid">
            {{ 'shared.errors.mustBeValid' | translate }}
          </span>
          <span
            *ngIf="
              userFormRef.submitted &&
              (!userForm.get('phone').value ||
                userForm.get('phone').hasError('minlength'))
            "
          >
            {{ 'signup.userInput.isRequired' | translate }} </span
          ><br />
          <zlo-phone-input
            formControlName="phone"
            (isValid)="isValidFn($event)"
            cy-data="signup-phone-input"
          ></zlo-phone-input>
          <div class="margin-bm"></div>
        </label>
      </div>
      <ng-container
        formArrayName="attributes"
        *ngFor="let attribute of attributes.controls; index as i"
      >
        <div [formGroup]="attribute" class="full-width">
          <label
            [class.signup-form-error]="
              userFormRef.submitted && attribute.get('value').errors
            "
          >
            {{ attribute.value.name | titlecase }}
            <span
              *ngIf="
                userFormRef.submitted &&
                attribute.get('value').hasError('required')
              "
            >
              {{ 'signup.userInput.isRequired' | translate }}
            </span>
            <span
              *ngIf="
                userFormRef.submitted &&
                attribute.get('value').hasError('minlength')
              "
            >
              {{ 'signup.userInput.attributeTooShort' | translate }}
            </span>
            <span
              *ngIf="
                userFormRef.submitted &&
                attribute.get('value').hasError('maxlength')
              "
            >
              {{ 'signup.userInput.attributeTooLong' | translate }}
            </span>
            <input
              type="text"
              formControlName="value"
              cy-data="signup-attribute-input"
            />
          </label>
        </div>
      </ng-container>
      <button class="button margin-tm fixed-width-192" type="submit">
        {{ 'signup.messageSignup.submitButton' | translate }}
      </button>
    </form>
  </div>
</div>

<ng-template #signingup>
  <mat-progress-spinner
    mode="indeterminate"
    [diameter]="28"
  ></mat-progress-spinner>
  <p class="body-text margin-ts">{{ isSigningUpAnonymous | translate }}</p>
</ng-template>
