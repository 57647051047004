import * as AuthActions from '../actions/auth.actions';
import { createReducer, on, Action } from '@ngrx/store';

export interface State {
  pending: boolean;
  otpChannel: string;
  otpStatus: string;
}

export const initialState: State = {
  pending: false,
  otpChannel: null,
  otpStatus: null
};

const loginPageReducer = createReducer(
  initialState,
  on(AuthActions.Login, (state) => ({
    ...state,
    pending: true
  })),
  on(AuthActions.Otp, (state, action) => ({
    ...state,
    otpChannel: action.channel,
    otpStatus: action.status,
    pending: false
  })),
  on(AuthActions.LoginSuccess, (state) => ({
    ...state,
    pending: false
  })),
  on(AuthActions.Logout, () => initialState),
  on(AuthActions.LoginFailure, (state) => ({
    ...state,
    pending: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return loginPageReducer(state, action);
}

export const getPending = (state: State) => state.pending;
export const getOtpChannel = (state: State) => state.otpChannel;
export const getOtpStatus = (state: State) => state.otpStatus;
