import { createAction, props } from '@ngrx/store';
import {
  UserStatistics,
  ZeloResponse,
  EndUserSettings,
  PreferredChannel,
  StepTypeUnion
} from 'src/types';

export const enum EnduserActionTypes {
  LoadEnduserResponse = '[Enduser Response] Load',
  LoadEnduserResponseSuccess = '[Enduser Response] Load Success',
  LoadEnduserResponseFailure = '[Enduser Response] Load Failure',
  SendEnduserResponse = '[Enduser Response] Send',
  SendEnduserResponseSuccess = '[Enduser Response] Send Success',
  SendEnduserResponseFailure = '[Enduser Response] Send Failure',
  RetakeTest = '[Enduser Response] Retake test',
  updateEnduserResponseStep = '[Enduser Response Step] Update',
  updateEnduserResponseStepSuccess = '[Enduser Response Step] Update Success',
  updateEnduserResponseStepFailure = '[Enduser Response Step] Update Failure',
  LoadEnduserStatistics = '[Enduser statistics] Load',
  LoadEnduserStatisticsForTeams = '[Enduser statistics] Load (Teams)',
  LoadEnduserStatisticsSuccess = '[Enduser statistics] Load Success',
  LoadEnduserStatisticsFailure = '[Enduser statistics] Load Failure',
  LoadEnduserSettings = '[Enduser] Load Enduser Settings',
  LoadEnduserSettingsSuccess = '[Enduser] Load Enduser Settings Success',
  LoadEnduserSettingsFailure = '[Enduser] Load Enduser Settings Failure',
  SendEnduserSettings = '[Enduser] Send Enduser Settings',
  SendEnduserSettingsSuccess = '[Enduser] Send Enduser Settings Success',
  SendEnduserSettingsFailure = '[Enduser] Send Enduser Settings Failure',
  LoadAvailableChannels = '[Enduser] Load Enduser Available Channels',
  LoadAvailableChannelsSuccess = '[Enduser] Load Enduser Available Channels Success',
  LoadAvailableChannelsFailure = '[Enduser] Load Enduser Available Channels Failure'
}

/** Load Enduser Response */
export const loadEnduserResponse = createAction(
  EnduserActionTypes.LoadEnduserResponse,
  props<{ responseId: string; sessionStart?: boolean }>()
);

export const loadEnduserResponseSuccess = createAction(
  EnduserActionTypes.LoadEnduserResponseSuccess,
  props<{ response: ZeloResponse }>()
);

export const loadEnduserResponseFailure = createAction(
  EnduserActionTypes.LoadEnduserResponseFailure,
  props<{ error: any }>()
);

/** Send Enduser Response */
export const sendEnduserResponse = createAction(
  EnduserActionTypes.SendEnduserResponse,
  props<{
    zeloResponse: ZeloResponse;
    isCheckPoint: boolean;
    isTestAnswer: boolean;
  }>()
);

export const sendEnduserResponseSuccess = createAction(
  EnduserActionTypes.SendEnduserResponseSuccess,
  props<{ response: ZeloResponse }>()
);

export const sendEnduserResponseFailure = createAction(
  EnduserActionTypes.SendEnduserResponseFailure,
  props<{ error: any }>()
);

/** Retake Test */
export const retakeTest = createAction(
  EnduserActionTypes.RetakeTest,
  props<{ responseId: string }>()
);

/** Update Enduser Response */
export const updateEnduserResponseStep = createAction(
  EnduserActionTypes.updateEnduserResponseStep,
  props<{ step: StepTypeUnion; responseId: string }>()
);

export const updateEnduserResponseStepSuccess = createAction(
  EnduserActionTypes.updateEnduserResponseStepSuccess
);

export const updateEnduserResponseStepFailure = createAction(
  EnduserActionTypes.updateEnduserResponseStepFailure,
  props<{ error: any }>()
);

/** Load Enduser Statistics */
export const loadEnduserStatistics = createAction(
  EnduserActionTypes.LoadEnduserStatistics,
  props<{ userId: string }>()
);

export const loadEnduserStatisticsForTeams = createAction(
  EnduserActionTypes.LoadEnduserStatisticsForTeams,
  props<{ tenantId: string; remoteId: string }>()
);

export const loadEnduserStatisticsSuccess = createAction(
  EnduserActionTypes.LoadEnduserStatisticsSuccess,
  props<{ userStatistics: UserStatistics }>()
);

export const loadEnduserStatisticsFailure = createAction(
  EnduserActionTypes.LoadEnduserStatisticsFailure,
  props<{ error: any }>()
);

/** Load Enduser Settings */
export const loadEnduserSettings = createAction(
  EnduserActionTypes.LoadEnduserSettings,
  props<{ userId: string }>()
);

export const loadEnduserSettingsSuccess = createAction(
  EnduserActionTypes.LoadEnduserSettingsSuccess,
  props<{ endUserSettings: EndUserSettings }>()
);

export const loadEnduserSettingsFailure = createAction(
  EnduserActionTypes.LoadEnduserSettingsFailure,
  props<{ error: any }>()
);

export const sendEndUserSettings = createAction(
  EnduserActionTypes.SendEnduserSettings,
  props<{ userId: string; endUserSettings: EndUserSettings }>()
);

export const sendEnduserSettingsSuccess = createAction(
  EnduserActionTypes.SendEnduserSettingsSuccess,
  props<{ endUserSettings: EndUserSettings }>()
);

export const sendEndUserSettingsFailure = createAction(
  EnduserActionTypes.SendEnduserSettingsFailure,
  props<{ error: any }>()
);

export const loadAvailableChannels = createAction(
  EnduserActionTypes.LoadAvailableChannels,
  props<{ organizationId: string }>()
);

export const loadAvailableChannelsSuccess = createAction(
  EnduserActionTypes.LoadAvailableChannelsSuccess,
  props<{ availableChannels: PreferredChannel[] }>()
);

export const loadAvailableChannelsFailure = createAction(
  EnduserActionTypes.LoadAvailableChannelsFailure,
  props<{ error: any }>()
);
