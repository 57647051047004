import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AuthService } from 'src/app/auth/services/auth.service';
import { TOKEN_KEY } from 'src/app/auth/user.util';

import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';

import { AppComponent } from './core/containers/app.component';

export function appInitializer(authService: AuthService) {
  return () =>
    new Promise((resolve: any) => {
      if (localStorage.getItem(TOKEN_KEY)) {
        // attempt to refresh token on app start up to auto authenticate only if user is logged in
        authService.refreshToken().subscribe().add(resolve);
      } else {
        resolve(true);
      }
    });
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AuthModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-center' })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthService],
      multi: true
    },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
