import { Component } from '@angular/core';
import { SESSION_TIMEOUT } from 'src/app/shared/util';

@Component({
  selector: 'zlo-expired-session',
  templateUrl: './expired-session.component.html',
  styleUrls: ['./expired-session.component.scss']
})
export class ExpiredSessionComponent {
  getSessionTimeout() {
    return SESSION_TIMEOUT / 1000 / 60;
  }
}
