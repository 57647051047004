import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, of, tap } from 'rxjs';
import { RouterStateUrl } from 'src/app/core/reducers';
import { LoginSSOCode, LoginSSOCodeFailure } from '../actions/auth.actions';
import { Authenticate } from 'src/types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

@Component({
  selector: 'zlo-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./../containers/login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoComponent implements OnInit {
  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router
  ) {}

  @Input() routerState$: Observable<RouterStateUrl>;
  @Output() submitted = new EventEmitter<Authenticate>();

  hasCode$: Observable<boolean>;
  errorState$: Observable<string>;
  isSubmitted = false;

  form = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    sso: new FormControl<boolean>(true)
  });

  ngOnInit() {
    this.hasCode$ = this.routerState$.pipe(
      tap((routerState) => {
        if (routerState.queryParams.code) {
          this.store.dispatch(
            LoginSSOCode({ code: routerState.queryParams.code })
          );
        }
      }),
      map((routerState) => !!routerState.queryParams.code)
    );

    this.errorState$ = this.actions$.pipe(
      ofType(LoginSSOCodeFailure),
      map((err: any) => {
        return err?.error?.message;
      })
    );
  }

  resetSSO(): void {
    this.router.navigate([], {
      queryParams: {
        code: null
      },
      queryParamsHandling: 'merge'
    });
    this.hasCode$ = of(false);
    this.errorState$ = of('');
    this.form.reset();
  }

  submit() {
    this.isSubmitted = true;
    if (this.form.valid) {
      this.submitted.emit(this.form.value as Authenticate);
    }
  }
}
