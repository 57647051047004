import {
  Injectable,
  OnDestroy,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromAuth from '../../auth/reducers';
import * as CryptoJS from 'crypto-js';
import { User } from '../../../types';
import { isTestUser } from '../../adminPortal/shared/util';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SegmentService implements OnDestroy {
  loggedInUser: User | null;
  subscription: Subscription = new Subscription();
  private renderer: Renderer2;
  private isAnalyticsInitialized = false;

  constructor(
    private store: Store<fromAuth.State>,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.subscription.add(
      this.store.pipe(select(fromAuth.getUser)).subscribe((user: User) => {
        if (user) {
          this.loggedInUser = user;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadAnalyticsJS() {
    if (this.loggedInUser.organization.e2e || this.isAnalyticsInitialized)
      return;

    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      !(function () {
        var analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize)
          if (analytics.invoked)
            window.console &&
              console.error &&
              console.error('Segment snippet included twice.');
          else {
            analytics.invoked = !0;
            analytics.methods = [
              'trackSubmit',
              'trackClick',
              'trackLink',
              'trackForm',
              'pageview',
              'identify',
              'reset',
              'group',
              'track',
              'ready',
              'alias',
              'debug',
              'page',
              'once',
              'off',
              'on',
              'addSourceMiddleware',
              'addIntegrationMiddleware',
              'setAnonymousId',
              'addDestinationMiddleware'
            ];
            analytics.factory = function (e) {
              return function () {
                var t = Array.prototype.slice.call(arguments);
                t.unshift(e);
                analytics.push(t);
                return analytics;
              };
            };
            for (var e = 0; e < analytics.methods.length; e++) {
              var key = analytics.methods[e];
              analytics[key] = analytics.factory(key);
            }
            analytics.load = function (key, e) {
              var t = document.createElement('script');
              t.type = 'text/javascript';
              t.async = !0;
              t.src =
                'https://cdn.segment.com/analytics.js/v1/' +
                key +
                '/analytics.min.js';
              var n = document.getElementsByTagName('script')[0];
              n.parentNode.insertBefore(t, n);
              analytics._loadOptions = e;
            };
            analytics._writeKey = '${environment.analyticsKey}';
            analytics.SNIPPET_VERSION = '4.15.3';
            analytics.load('${environment.analyticsKey}');
          }
        })();
    `;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.head, script);
    this.isAnalyticsInitialized = true;
  }

  identifySegmentUser = (
    user: User = this.loggedInUser,
    options: { isSignupFlow: boolean; googleAnalytics: boolean } = {
      isSignupFlow: false,
      googleAnalytics: false
    }
  ) => {
    if (!this.isAnalyticsInitialized) return;
    if (!user) return;
    if (isTestUser(user)) return;

    (<any>window).analytics.identify(
      user.id,
      {
        name: user.firstname + ' ' + user.lastname,
        email: user.email,
        isAdmin: user.roles?.isAdmin,
        isManager: user.roles?.isManager,
        createdAt: user.createdAt,
        language: user.organization?.language,
        department: user.departments[0]?.name,
        isSpamaUser: ['spama'].includes(user.organization.name.toLowerCase()),
        isProduction: environment.production,
        isInSingupFlow: options.isSignupFlow,
        company: {
          name: user.organization?.name,
          id: user.organization?.id,
          plan: user.organization?.tier,
          isProduction: environment.production
        }
      },
      {
        integrations: {
          Intercom: {
            user_hash: CryptoJS.enc.Hex.stringify(
              CryptoJS.HmacSHA256(user.id, environment.intercomVerificationKey)
            )
          },
          'Google Analytics': options.googleAnalytics
        }
      }
    );
    (<any>window).analytics.group(user.organization.id, {
      name: user.organization.name
    });
  };

  track = (
    event: string,
    properties: any = {},
    options: { googleAnalytics: boolean } = { googleAnalytics: false }
  ) => {
    if (!this.isAnalyticsInitialized) return;
    if (!this.loggedInUser) return;
    if (isTestUser(this.loggedInUser)) return;

    (<any>window).analytics.track(event, properties, {
      integrations: {
        'Google Analytics': options.googleAnalytics
      }
    });
  };

  trackAnonymous = (
    event: string,
    properties: any = {},
    options: { googleAnalytics: boolean } = { googleAnalytics: false }
  ) => {
    if (!this.isAnalyticsInitialized) return;
    (<any>window).analytics.track(event, properties, {
      integrations: {
        'Google Analytics': options.googleAnalytics
      }
    });
  };
}
