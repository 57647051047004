import { createAction, props } from '@ngrx/store';
import {
  Authenticate,
  Registration,
  PasswordChange,
  OtpResult,
  AuthenticateRepsonse
} from 'src/types';

export enum AuthActionTypes {
  Init = '[Auth] INIT',
  NOOP = 'NOOP',
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  Forgot = '[Auth] Forgot',
  Change = '[Auth] Change',
  LoginSuccess = '[Auth] Login Success',
  LoginFailure = '[Auth] Login Failure',
  LoginRedirect = '[Auth] Login Redirect',
  Otp = '[Auth] One Time Password',
  Register = '[Auth] Register',
  ForgotSuccess = '[Auth] Forgot Success',
  ForgotFailure = '[Auth] Forgot Failure',
  ChangeSuccess = '[Auth] Change Success',
  ChangeFailure = '[Auth] Change Failure',
  OverviewLink = '[Auth] Overview Link',
  OverviewLinkSuccess = '[Auth] Overview Link Success',
  OverviewLinkFailure = '[Auth] Overview Link Failure',
  SetSessionStateUninitialized = '[Auth] Set Session State Uninitialized',
  SetSessionStateActive = '[Auth] Set Session State Active',
  SetSessionStateIdle = '[Auth] Set Session State Idle',
  ExpireSession = '[Auth] ExpireSession',
  LoginSSO = '[Auth] Initiale SSO Login',
  LoginSSOCode = '[Auth] Post SSO Code',
  LoginSSOCodeFailure = '[Auth] Post SSO Code Failure'
}

export const Init = createAction(AuthActionTypes.Init);

export const Noop = createAction(AuthActionTypes.NOOP);

export const Login = createAction(AuthActionTypes.Login, props<Authenticate>());

export const Otp = createAction(AuthActionTypes.Otp, props<OtpResult>());

export const Register = createAction(
  AuthActionTypes.Register,
  props<Registration>()
);

export const LoginSuccess = createAction(
  AuthActionTypes.LoginSuccess,
  props<AuthenticateRepsonse>()
);

export const LoginFailure = createAction(
  AuthActionTypes.LoginFailure,
  props<any>()
);

export const LoginRedirect = createAction(AuthActionTypes.LoginRedirect);

export const Logout = createAction(AuthActionTypes.Logout);

export const ExpireSession = createAction(AuthActionTypes.ExpireSession);

export const Forgot = createAction(
  AuthActionTypes.Forgot,
  props<{ payload: string }>()
);
//
export const ForgotSuccess = createAction(AuthActionTypes.ForgotSuccess);

export const ForgotFailure = createAction(
  AuthActionTypes.ForgotFailure,
  props<any>()
);

export const Change = createAction(
  AuthActionTypes.Change,
  props<PasswordChange>()
);

export const ChangeSuccess = createAction(AuthActionTypes.ChangeSuccess);

export const ChangeFailure = createAction(
  AuthActionTypes.ChangeFailure,
  props<any>()
);

export const OverviewLink = createAction(
  AuthActionTypes.OverviewLink,
  props<{ payload: string }>()
);

export const OverviewLinkSuccess = createAction(
  AuthActionTypes.OverviewLinkSuccess
);

export const OverviewLinkFailure = createAction(
  AuthActionTypes.OverviewLinkFailure,
  props<any>()
);

export const SetSessionStateUninitialized = createAction(
  AuthActionTypes.SetSessionStateUninitialized
);

export const SetSessionStateActive = createAction(
  AuthActionTypes.SetSessionStateActive
);

export const SetSessionStateIdle = createAction(
  AuthActionTypes.SetSessionStateIdle
);

export const LoginSSO = createAction(
  AuthActionTypes.LoginSSO,
  props<Authenticate>()
);

export const LoginSSOCode = createAction(
  AuthActionTypes.LoginSSOCode,
  props<{ code: string }>()
);

export const LoginSSOCodeFailure = createAction(
  AuthActionTypes.LoginSSOCodeFailure,
  props<any>()
);
