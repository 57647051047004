import { Subscription } from 'rxjs/internal/Subscription';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PaginatorI18n extends MatPaginatorIntl implements OnDestroy {
  sub: Subscription;
  constructor(private translate: TranslateService) {
    super();
    this.getPaginatorIntl();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getPaginatorIntl() {
    this.sub = this.translate
      .get([
        'paginator.itemsPerPageLabel',
        'paginator.firstPageLabel',
        'paginator.previousPageLabel',
        'paginator.nextPageLabel',
        'paginator.lastPageLabel'
      ])
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation['paginator.itemsPerPageLabel'];
        this.firstPageLabel = translation['paginator.firstPageLabel'];
        this.previousPageLabel = translation['paginator.previousPageLabel'];
        this.nextPageLabel = translation['paginator.nextPageLabel'];
        this.lastPageLabel = translation['paginator.lastPageLabel'];
        this.changes.next();
      });
  }
}
