import {
  ZeloResponse,
  UserStatistics,
  EndUserSettings,
  PreferredChannel
} from 'src/types';
import * as EnduserActions from '../actions/enduser.actions';
import { createReducer, Action, on } from '@ngrx/store';

export interface State {
  response: ZeloResponse;
  loading: boolean;
  userStatistics: UserStatistics;
  endUserSettings: EndUserSettings;
  availableChannels: PreferredChannel[];
}

export const initialState: State = {
  response: null,
  loading: false,
  userStatistics: null,
  endUserSettings: null,
  availableChannels: null
};

const enduserReducer = createReducer(
  initialState,
  on(
    EnduserActions.loadEnduserStatistics,
    EnduserActions.loadEnduserStatisticsForTeams,
    EnduserActions.loadEnduserResponse,
    EnduserActions.loadEnduserSettings,
    EnduserActions.loadAvailableChannels,
    EnduserActions.retakeTest,
    (state) => ({
      ...state,
      loading: true
    })
  ),
  on(EnduserActions.sendEnduserResponse, (state, { zeloResponse }) => ({
    ...state,
    response: zeloResponse,
    loading: true
  })),
  on(
    EnduserActions.sendEnduserResponseSuccess,
    EnduserActions.loadEnduserResponseSuccess,
    (state, { response }) => ({
      ...state,
      response,
      loading: false
    })
  ),
  on(
    EnduserActions.loadEnduserStatisticsSuccess,
    (state, { userStatistics }) => ({
      ...state,
      userStatistics,
      loading: false
    })
  ),
  on(
    EnduserActions.loadAvailableChannelsSuccess,
    (state, { availableChannels }) => ({
      ...state,
      availableChannels,
      loading: false
    })
  ),
  on(
    EnduserActions.loadEnduserSettingsSuccess,
    EnduserActions.sendEnduserSettingsSuccess,
    (state, { endUserSettings }) => ({
      ...state,
      endUserSettings,
      loading: false
    })
  ),
  on(
    EnduserActions.loadEnduserStatisticsFailure,
    EnduserActions.sendEnduserResponseFailure,
    EnduserActions.loadEnduserResponseFailure,
    EnduserActions.loadEnduserSettingsFailure,
    EnduserActions.sendEndUserSettingsFailure,
    (state) => ({
      ...state,
      loading: false
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return enduserReducer(state, action);
}

export const getZeloResponse = (state: State) => state.response;
export const getZeloResponseLoadingStatus = (state: State) => state.loading;
export const getUserStatistics = (state: State) => state.userStatistics;
