import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth.component';
import { LoginPageComponent } from './containers/login-page.component';
import { ExpiredSessionComponent } from './containers/expired-session.component';
import { OverviewLinkPromptComponent } from './containers/overview-link-prompt.component';
import { NotFoundComponent } from '../core/components/not-found/not-found.component';
import { AuthRedirectGuard } from '../auth/services/auth-redirect.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    canActivate: [AuthRedirectGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'login' },
      { path: 'login', component: LoginPageComponent },
      { path: 'forgot', component: LoginPageComponent },
      { path: 'change', component: LoginPageComponent },
      { path: 'sso', component: LoginPageComponent },
      { path: 'endusers', component: OverviewLinkPromptComponent },
      { path: 'expired-session', component: ExpiredSessionComponent }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
