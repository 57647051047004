<zlo-background>
  <div class="cell auto">
    <ng-container *ngIf="routerState$ | async as routerState">
      <zlo-login-form
        (submitted)="onSubmit($event, routerState.url)"
        [pending]="pending$ | async"
        [otpChannel]="otpChannel"
        [otpInvalid]="otpInvalid"
        [otpSendLimit]="otpSendLimit"
        [otpVerifyLimit]="otpVerifyLimit"
        [otpTimeout]="otpTimeout"
        *ngIf="routerState.url === '/auth/login'"
      >
      </zlo-login-form>

      <zlo-sso
        *ngIf="routerState.url.includes('/auth/sso')"
        [routerState$]="routerState$"
        (submitted)="onSubmit($event, routerState.url)"
      ></zlo-sso>

      <zlo-forgot-password
        (submitted)="onSubmit($event, routerState.url)"
        [pending]="pending$ | async"
        *ngIf="routerState.url === '/auth/forgot'"
      ></zlo-forgot-password>
    </ng-container>
  </div>
</zlo-background>
