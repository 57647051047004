import { Observable } from 'rxjs';

export interface MenuItem {
  name: string;
  link: string;
  icon?: string;
  count$?: Observable<number>;
}

export interface FileData {
  url: string;
  title: string;
}

export type Language = 'en' | 'no' | 'spama' | 'se' | 'dk' | 'fi';

export interface LanguageObject {
  id: Language;
  name: string;
  flag: string; // This is the flag icon class name
}

export const languageList: { [key: string]: LanguageObject } = {
  en: { id: 'en', name: 'EN', flag: 'fi-us' },
  no: { id: 'no', name: 'NO', flag: 'fi-no' },
  spama: { id: 'spama', name: 'NO eLearning', flag: 'fi-no' },
  fi: { id: 'fi', name: 'FI', flag: 'fi-fi' },
  se: { id: 'se', name: 'SE', flag: 'fi-se' },
  dk: { id: 'dk', name: 'DK', flag: 'fi-dk' }
};
