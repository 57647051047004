import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap
} from '@ngrx/store';
import * as fromRoot from '../../core/reducers';
import * as fromAuth from './auth.reducer';
import * as fromLoginPage from './login-page.reducer';

export interface AuthState {
  status: fromAuth.State;
  loginPage: fromLoginPage.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const reducers: ActionReducerMap<AuthState> = {
  status: fromAuth.reducer,
  loginPage: fromLoginPage.reducer
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: AuthState) => state.status
);
export const getLoggedIn = createSelector(
  selectAuthStatusState,
  fromAuth.getLoggedIn
);
export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);

export const userCanEdit = createSelector(selectAuthStatusState, (state) => {
  return state.user?.permissions?.zelos?.canEdit || false;
});

export const getOrganization = createSelector(getUser, (user) =>
  user ? user.organization : null
);

export const getOrganizationChannels = createSelector(
  getOrganization,
  (organization) => {
    return organization?.channels;
  }
);

export const getOrganizationDepartments = createSelector(
  getOrganization,
  (organization) => {
    return organization?.departments;
  }
);

export const getOrganizationDomains = createSelector(
  getOrganization,
  (organization) => {
    return organization?.settings?.whitelabel?.domains;
  }
);

export const getOrganizationSenders = createSelector(
  getOrganization,
  (organization) => {
    return organization?.senders;
  }
);

export const isWorkplaceEnabled = createSelector(
  getOrganizationChannels,
  (channels) => {
    const workplaceChannel = channels?.workplace;

    return workplaceChannel?.isActive;
  }
);

export const isDemoOrganization = createSelector(
  getOrganization,
  (organization) =>
    organization && organization.tier && organization.tier === 'FREE'
);

export const getPermissions = createSelector(
  selectAuthStatusState,
  fromAuth.getPermissions
);

export const selectLoginPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.loginPage
);

export const getLoginPagePending = createSelector(
  selectLoginPageState,
  fromLoginPage.getPending
);

export const getOtpChannel = createSelector(
  selectLoginPageState,
  fromLoginPage.getOtpChannel
);

export const getOtpStatus = createSelector(
  selectLoginPageState,
  fromLoginPage.getOtpStatus
);

export const isSessionActive = createSelector(
  selectAuthStatusState,
  fromAuth.isSessionActive
);

export const isSessionIdle = createSelector(
  selectAuthStatusState,
  fromAuth.isSessionIdle
);
