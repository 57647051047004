<div class="grid-x align-center text-center">
  <div class="cell">
    <form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="submit()">
      <div class="login-form-container grid-x">
        <div class="cell text-left">
          <label class="labels form">
            {{ 'auth.registration.firstNameLabel' | translate }}
            <span
              class="error-list"
              *ngIf="
                ngForm.submitted && form.get('firstName').hasError('required')
              "
            >
              {{ 'auth.errors.isRequired' | translate }}
            </span>
            <input
              class="input standard"
              type="text"
              [placeholder]="
                'auth.registration.firstNamePlaceholder' | translate
              "
              formControlName="firstName"
              autocomplete="given-name"
              autofocus="true"
            />
          </label>
        </div>
        <div class="cell text-left">
          <label class="labels form">
            {{ 'auth.registration.lastNameLabel' | translate }}
            <span
              class="error-list"
              *ngIf="
                ngForm.submitted && form.get('lastName').hasError('required')
              "
            >
              {{ 'auth.errors.isRequired' | translate }}
            </span>
            <input
              class="input standard"
              type="text"
              [placeholder]="
                'auth.registration.lastNamePlaceholder' | translate
              "
              formControlName="lastName"
              autocomplete="family-name"
            />
          </label>
        </div>
        <div class="cell text-left">
          <label class="labels form">
            {{ 'auth.emailLabel' | translate }}
            <span
              class="error-list"
              *ngIf="ngForm.submitted && form.get('email').hasError('required')"
            >
              {{ 'auth.errors.isRequired' | translate }}</span
            >
            <span
              class="error-list"
              *ngIf="ngForm.submitted && form.get('email').hasError('email')"
            >
              {{ 'auth.errors.isInvalid' | translate }}</span
            >
            <input
              class="input standard"
              type="email"
              [placeholder]="'jane.doe@acme.com'"
              formControlName="email"
              autocomplete="email"
            />
          </label>
        </div>
        <div class="cell text-left">
          <label class="labels form">
            {{ 'auth.login.passwordLabel' | translate }}
            <span
              class="error-list"
              *ngIf="
                ngForm.submitted && form.get('password').hasError('required')
              "
            >
              {{ 'shared.errors.isRequired' | translate }}</span
            >
            <span
              class="error-list"
              *ngIf="
                ngForm.submitted && form.get('password').hasError('minlength')
              "
            >
              {{ 'auth.errors.passwordMinLength' | translate }}</span
            >
            <input
              class="input standard"
              type="password"
              [placeholder]="'auth.login.passwordPlaceholder' | translate"
              formControlName="password"
              autocomplete="new-password"
            />
          </label>
        </div>
        <div class="cell text-left">
          <label class="labels form">
            {{ 'auth.registration.orgNameLabel' | translate }}
            <span
              class="error-list"
              *ngIf="
                ngForm.submitted && form.get('orgName').hasError('required')
              "
            >
              {{ 'auth.errors.isRequired' | translate }}
            </span>
            <input
              class="input standard"
              [ngClass]="nameCheckClass()"
              type="text"
              [placeholder]="'auth.registration.orgNamePlaceholder' | translate"
              formControlName="orgName"
              (keyup)="nameCheck($event)"
            />
            <span class="name-check" [ngClass]="nameCheckClass()">
              {{ nameCheckText() }}</span
            >
          </label>
        </div>
        <div class="cell">
          <button
            class="button expanded continue-button"
            type="submit"
            mat-button
            [disabled]="nameCheckClass() === 'name-check-collision'"
          >
            {{ 'auth.registration.continue' | translate }}
          </button>
        </div>
        <ng-template>
          <p class="body-text light">
            {{ 'auth.registration.byProceeding' | translate }}&nbsp;
            <a
              href="https://www.zeloapp.com/resources/terms-of-service"
              target="_blank"
              rel="noopener"
            >
              {{ 'auth.registration.termsOfService' | translate }}</a
            >&nbsp; {{ 'auth.registration.andOur' | translate }}&nbsp;
            <a
              href="https://www.zeloapp.com/resources/privacy-policy"
              target="_blank"
              rel="noopener"
            >
              {{ 'auth.registration.privacyPolicy' | translate }}</a
            >.
          </p>
        </ng-template>
      </div>
    </form>
  </div>
</div>
