import {
  Validators,
  UntypedFormControl,
  ValidatorFn,
  ValidationErrors,
  AbstractControl
} from '@angular/forms';
import { timer } from 'rxjs';
import { catchError, map, switchMap, filter } from 'rxjs/operators';

import { Employee } from 'src/types';
import { checkDuplicates } from '../adminPortal/shared/util';
import { EmployeeService } from '../adminPortal/employee/services/employee.service';

export const TOKEN_KEY = 'id_token';
export const USER_KEY = 'zelo_user';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const PERMISSIONS_KEY = 'user_permissions';
export const ENV_KEY = 'env';

export const MIN_PASSWORD_LENGTH = 4;
export const NEW_PASSWORD_LENGTH = 8;

const DELAY_TIME = 500;

export class UserValidators {
  static emailValidator: ValidatorFn = (
    control: UntypedFormControl
  ): ValidationErrors | Boolean => {
    const isValid = control.value
      .trim()
      .match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
    return isValid ? null : { email: true };
  };

  static requiredEmail = Validators.compose([
    Validators.required,
    UserValidators.emailValidator
  ]);

  static otpValidator = Validators.compose([
    Validators.pattern('^[0-9]{6,6}$')
  ]);

  /** Required and minimum 8 characters (new) */
  static requiredPasswordExtended = Validators.compose([
    Validators.required,
    Validators.minLength(NEW_PASSWORD_LENGTH)
  ]);

  /** Required and minimum 4 characters (old, will eventually be deprecated) */
  static requiredPassword = Validators.compose([
    Validators.required,
    Validators.minLength(MIN_PASSWORD_LENGTH)
  ]);

  static requiredPhone = Validators.compose([
    Validators.required,
    Validators.pattern('')
  ]);

  static equalPasswords: ValidatorFn = (
    form: UntypedFormControl
  ): ValidationErrors | null => {
    const newPasswordControl = form.get('newPassword');
    const repeatPasswordControl = form.get('repeatPassword');

    if (!newPasswordControl || !repeatPasswordControl) {
      throw new Error(
        `Can not find oldPassword or repeatPassword in the form: ${form.toString()}`
      );
    }

    const newPassword = newPasswordControl.value;
    const repeatPassword = repeatPasswordControl.value;

    let errors = {};

    if (newPasswordControl.hasError) {
      errors = { ...errors, ...newPasswordControl.errors };
    }

    if (repeatPasswordControl.hasError) {
      errors = { ...errors, ...repeatPasswordControl.errors };
    }

    if (
      newPassword &&
      repeatPassword &&
      newPassword !== repeatPassword &&
      repeatPasswordControl.dirty
    ) {
      errors = { ...errors, passwordNoMatch: true };
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  static emailOrPhone: ValidatorFn = (
    form: UntypedFormControl
  ): ValidationErrors | null => {
    const emailControl = form.get('email');
    const phoneControl = form.get('phone');

    if (!emailControl || !phoneControl) {
      throw new Error(
        `Can not find email or phone in the form: ${form.toString()}`
      );
    }

    const email = emailControl.value;
    const phone = phoneControl.value;

    if (!email && !phone) {
      return {
        emailOrPhoneRequired: true
      };
    }

    if (
      !UserValidators.requiredEmail(emailControl) &&
      !UserValidators.requiredPhone(phoneControl)
    ) {
      return null;
    }

    if (email) {
      return UserValidators.requiredEmail(emailControl);
    }

    if (phone) {
      return UserValidators.requiredPhone(phoneControl);
    }

    return {
      unknownError: true
    };
  };

  static passwordChange: ValidatorFn = (
    form: UntypedFormControl
  ): ValidationErrors | null => {
    const oldPasswordControl = form.get('oldPassword');
    const newPasswordControl = form.get('newPassword');

    if (!oldPasswordControl || !newPasswordControl) {
      throw new Error(
        `Can not find oldPassword or newPassword in the form: ${form.toString()}`
      );
    }

    const oldPassword = oldPasswordControl.value;
    const newPassword = newPasswordControl.value;

    if (oldPassword === newPassword) {
      return {
        passwordNotChanged: true
      };
    }

    const passEqualRes = UserValidators.equalPasswords(form);

    return passEqualRes ? passEqualRes : null;
  };

  static requireUniqueEmails: ValidatorFn = (
    form: UntypedFormControl
  ): ValidationErrors | null => {
    const emails = Object.values(form.value).map(
      (employee: Employee) => employee.email
    );

    const duplicates = checkDuplicates(emails);

    const duplicateIndexes = [];

    for (const key in form.value) {
      if (
        form.value.hasOwnProperty(key) &&
        duplicates.duplicates.includes(form.value[key].email)
      ) {
        duplicateIndexes.push(key);
        form.get(key).setErrors({ duplicateEmail: true });
        form.get(key).get('email').setErrors({ duplicateEmail: true });
      } else {
        form.get(key).setErrors(null);
        form.get(key).get('email').setErrors(null);
      }
    }
    return duplicateIndexes.length ? { duplicateIndexes } : null;
  };

  static requireUniqueEmailAsync(employeeService: EmployeeService) {
    return (control: AbstractControl) =>
      timer(DELAY_TIME).pipe(
        filter(() => !!control.value),
        switchMap(() =>
          employeeService.isEmailAvailable(control.value).pipe(
            map(({ existsInOrg }) =>
              existsInOrg ? null : { uniqueEmailError: true }
            ),
            catchError(() => null)
          )
        )
      );
  }
}
